import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Card } from "components/anti/card/card"
import bgWhite from "assets/img/bg_white.jpg"

export const BantuanTopic = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  return (
    <section className="py-main sc-bantuan-topic" ref={trigger}>
      <div className="container">
        <h2 className={`${anim(1)} caption`}>{data.label}</h2>
        <div className="row">
          {data?.list?.map((topic, i) => (
            <div className="col-6 col-md-4 col-lg-3" key={i}>
              <Card
                variant="overlay"
                link={`/bantuan/${topic?.services?.slug}`}
                img={bgWhite}
                imgRatio="r-16-9"
                imgOverlay="0"
                className={`${anim(2 + i)} card-help`}
              >
                <img src={topic.icon.sourceUrl} className="img-fluid" alt={topic.text} />
                <p>{topic.text}</p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
