import React from "react"

import { Cover } from "../../anti/cover/cover"

export const BantuanCover = ({ data }) => {
  return (
    <div className="sc-help-cover cover-full position-relative">
      <Cover
        // variant="responsive"
        theme="dark"
        img={data.image.sourceUrl}
        imgHeight="r-1-1 h-sm-400px h-md-500px"
        title={data.title}
        text={data.text}
        className="cover-help animated fadeInUp"
        titleClassName={`font-weight-normal`}
        textClassName={`mw-250px mw-md-300px`}
      />
      <div className="bg-gradient h-100 w-100" />
    </div>
  )
}
