import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"

import { BantuanCover } from "components/pages/bantuan/cover"
import { BantuanTopic } from "components/pages/bantuan/topic"

const Bantuan = () => {
  const data = useStaticQuery(GET_BANTUAN_DETAILS)
  const blocks = data?.wordPress?.page?.bantuanPage
  return (
    <Layout>
      <Seo title="Bantuan" />
      <BantuanCover data={blocks.cover} />
      <BantuanTopic data={blocks.topik} />
    </Layout>
  )
}

export default Bantuan

const GET_BANTUAN_DETAILS = graphql`
query Bantuan {
  wordPress {
    page(id: "bantuan", idType: URI) {
      bantuanPage {
        cover {
          text
          title
          image {
            sourceUrl
          }
        }
        topik {
          label
          list {
            text
            icon {
              sourceUrl
            }
            services {
              slug
            }
          }
        }
      }
    }
  }
}

`
